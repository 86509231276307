import React from 'react'

const Footer = ({ onContactClick }) => {
    return (
        <div id="footer">
            <hr className="footer-hr" />

            <div className="copyright">
                <a
                    href="mailto:ganalytrics@gmail.com?subject=Regarding GoogleAnalytrics"
                    style={{textDecoration: "none", display: "block", color: "hsl(205, 59%, 52%)", fontWeight: "500"}}
                    onClick={onContactClick}
                >
                    Contact
                </a>
                <a href="#" style={{color: "hsl(205, 59%, 52%)", fontWeight: "500", textDecoration: "none" }}>GoogleAnalytrics</a>
            </div>
        </div>
    )
}

export default Footer